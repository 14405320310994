import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "error-msg" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "error-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass({
            error: ($props.parent == '' && $props.v$ && $props.v$.value && $props.v$.value[$props.propname ?? $props.name] && $props.v$.value[$props.propname ?? $props.name].$errors.length) ||
                ($props.parent != '' && $props.v$ && $props.v$.value && $props.v$.value[$props.parent] && $props.v$.value[$props.parent].$each.$response.$errors.length && $props.v$.value[$props.parent].$each.$response.$errors[$props.propname ?? $props.name.split('_')[0]][$props.propname ?? $props.name.split('_')[1]].length)
        })
    }, [
      _createVNode(_component_Dropdown, {
        id: $props.name,
        name: $props.name,
        modelValue: $props.modelValue,
        placeholder: $props.placeholder,
        options: $setup.ListOption,
        loading: $setup.Isloading,
        optionLabel: $props.optionLabel,
        optionValue: $props.optionValue,
        filter: $props.filter,
        mode: $props.mode,
        showClear: $props.showClear,
        datatype: $props.datatype,
        autoFilterFocus: true,
        class: "w-full",
        onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateValue($event.target.value))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.updateValue($event.value))),
        disabled: $props.disabled,
        readonly: $props.readonly,
        dataurl: $props.dataurl,
        appendTo: $props.appendTo
      }, null, 8 /* PROPS */, ["id", "name", "modelValue", "placeholder", "options", "loading", "optionLabel", "optionValue", "filter", "mode", "showClear", "datatype", "disabled", "readonly", "dataurl", "appendTo"])
    ], 2 /* CLASS */),
    ($props.v$ && $props.v$.value && $props.v$.value[$props.propname ?? $props.name])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.v$.value[$props.propname ?? $props.name].$errors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "input-errors",
              key: error.$uid
            }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(error.$message), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true),
    ($props.parent != '' && $props.v$ && $props.v$.value && $props.v$.value[$props.parent] && $props.v$.value[$props.parent].$each.$response.$errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.v$.value[$props.parent].$each.$response.$errors[$props.propname ?? $props.name.split('_')[0]][$props.propname ?? $props.name.split('_')[1]], (error) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "input-errors",
              key: error.$uid
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(error.$message), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}