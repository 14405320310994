import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.SubmitData($event)), ["prevent"])),
    class: "p-fluid",
    autocomplete: "off"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 32 /* NEED_HYDRATION */))
}