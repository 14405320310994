import { resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pi pi-check"
}
const _hoisted_2 = {
  key: 1,
  class: "pi pi-times"
}
const _hoisted_3 = {
  key: 2,
  class: "pi pi-exclamation-triangle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_DynamicDialog = _resolveComponent("DynamicDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, { name: "layout" }),
    _createVNode(_component_router_view),
    _createVNode(_component_Toast, {
      class: "CustomToast",
      position: $setup.position
    }, {
      message: _withCtx((slotProps) => [
        _createCommentVNode(" <i :class=\"slotProps.message.icon\"></i> "),
        _createCommentVNode(" <i :class=\"slotProps.message.severity == 'success' ? 'pi pi-check' : 'pi pi-times'\"></i> "),
        (slotProps.message.severity == 'success')
          ? (_openBlock(), _createElementBlock("i", _hoisted_1))
          : _createCommentVNode("v-if", true),
        (slotProps.message.severity == 'error')
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : _createCommentVNode("v-if", true),
        (slotProps.message.severity == 'warn')
          ? (_openBlock(), _createElementBlock("i", _hoisted_3))
          : _createCommentVNode("v-if", true),
        _createElementVNode("h4", null, _toDisplayString(slotProps.message.summary), 1 /* TEXT */),
        _createElementVNode("p", null, [
          _createTextVNode(_toDisplayString(slotProps.message.detail) + " ", 1 /* TEXT */),
          _createVNode(_component_router_link, { to: "#" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(slotProps.message.link), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["position"]),
    _createVNode(_component_ConfirmDialog),
    _createVNode(_component_DynamicDialog)
  ], 64 /* STABLE_FRAGMENT */))
}