class SearchOperations {
    static equals = "Equals";
    static notEqual = "NotEqual";
    static contains = "Contains";
    static startsWith = "StartsWith";
    static endsWith = "EndsWith";
    static between = "BETWEEN";
    static greaterThan = "GreaterThan";
    static greaterThanOrEqual = "GreaterThanOrEqual";
    static lessThan = "LessThan";
    static lessThanOrEqual = "LessThanOrEqual";
    static inClause = "InClause";
    static dateRange = "DateRange";
}

export default SearchOperations;
