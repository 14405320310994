import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "error-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass({ error: $props.v$ && $props.v$.value && $props.v$.value[$props.propname ?? $props.name] && $props.v$.value[$props.propname ?? $props.name].$errors.length })
    }, [
      _createVNode(_component_Calendar, {
        id: $props.name,
        name: $props.name,
        modelValue: $props.modelValue,
        autocomplete: $props.autocomplete,
        dateFormat: $props.dateFormat,
        minDate: $props.minDate,
        maxDate: $props.maxDate,
        selectionMode: $props.selectionMode,
        disabled: $props.disabled,
        propname: $props.propname,
        showIcon: $props.showIcon,
        class: "w-full",
        timeOnly: $props.timeOnly,
        onDateSelect: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateValue($event))),
        onInput: _cache[1] || (_cache[1] = ($event: any) => ($setup.updateValue($event.target.value))),
        onClearClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.updateValue($event))),
        showButtonBar: $props.showButtonBar,
        selectOtherMonths: $props.selectOtherMonths,
        showTime: $props.showTime
      }, null, 8 /* PROPS */, ["id", "name", "modelValue", "autocomplete", "dateFormat", "minDate", "maxDate", "selectionMode", "disabled", "propname", "showIcon", "timeOnly", "showButtonBar", "selectOtherMonths", "showTime"])
    ], 2 /* CLASS */),
    ($props.v$ && $props.v$.value && $props.v$.value[$props.propname ?? $props.name])
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.v$.value[$props.propname ?? $props.name].$errors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "input-errors",
              key: error.$uid
            }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(error.$message), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}