import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    label: $props.btnlabel,
    class: _normalizeClass($props.btnclass),
    type: $props.btntype,
    icon: $props.btnicon,
    disabled: $props.disabled
  }, null, 8 /* PROPS */, ["label", "class", "type", "icon", "disabled"]))
}