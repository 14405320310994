import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vld-parent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      active: $setup.isLoading,
      "can-cancel": true,
      "on-cancel": $setup.onCancel,
      "is-full-page": $setup.fullPage
    }, null, 8 /* PROPS */, ["active", "is-full-page"])
  ]))
}