import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info__msg"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "error-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass({ error: $props.v$ && $props.v$.value && $props.v$.value[$props.propname ?? $props.name] && $props.v$.value[$props.propname ?? $props.name].$errors.length })
    }, [
      _createVNode(_component_Textarea, {
        id: $props.name,
        name: $props.name,
        value: $props.modelValue,
        autoResize: $props.autoResize,
        rows: $props.rows,
        cols: $props.cols,
        readonly: $props.IsreadOnly,
        disabled: $props.disabled,
        class: _normalizeClass($props.inputclass),
        showLength: $props.showLength,
        propname: $props.propname,
        maxlength: $props.maxLength,
        onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.updateValue($event.target.value))),
        ref: "InputValue",
        onKeyup: $setup.charCount
      }, null, 8 /* PROPS */, ["id", "name", "value", "autoResize", "rows", "cols", "readonly", "disabled", "class", "showLength", "propname", "maxlength"]),
      ($setup.Props.showLength)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($setup.totalChar) + " characters remaining", 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    ($props.v$ && $props.v$.value && $props.v$.value[$props.propname ?? $props.name])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.v$.value[$props.propname ?? $props.name].$errors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "input-errors",
              key: error.$uid
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(error.$message), 1 /* TEXT */)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}