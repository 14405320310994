class UrlConstants {
  //#region
  static loginUrl = "Login/Authenticate";
  static ForgotUrl = "Login/ForgotPassword";
  static ForgotValidateUrl = "Login/ValidateResetPasswordLink";
  static ResetPasswordUrl = "Login/ResetPassword";
  static adLoginUrl = "Login/AdAuthenticate";
  static logoutUrl = "Login/Logout";
  static apiGetLoginUserProfile = "User/get-user-profile";
  static apiLoginUserChangePassword = "User/change-password";
  static apiGetPermissionBasedOnRole = "Permission/get-role-based-permission-data";
  //#endregion
  //#region
  static captchUrl = "Login/GetCaptcha";
  static apiGetImage="Image/get-image";
  //#endregion
  //#region
  static Permissions = "Permission";
  static apiSavePermissions = "Permission/SavePermission";
  //#endregion
  // company
  static apidropdown = "DropDown/GetBindDropDown";
  //#region  :: User ::
  static apiuser = "User";
  static apiuserlist = "User/List";
  static apiADUserlist = "User/FilterADUser";
  static apiuserupdatestatus = "User/update-status";
  static apiuserupdatelockstatus = "User/update-lockstatus";
  static apiuserprofile = "User/GetUserProfile";
  static apiuserupdateprofile = "User/UpdateProfile";
  static apiuserchangepassword = "User/ChangePassword";
  static apiuserdeactive = "User/DeactiveStatus";
  static apiuseraduserlist = "User/GetADUsers";
  static apiuseraduserphoto = "User/GetAdUserPhoto";
  static apiuseradid = "User/GetAdId";
  static apiBusinessUnitByCountryid = "User/get-BU-by-country";
  //#endregion
  
  //#region  :: Role ::
  static apirole = "Role";
  static apirolelist = "Role/Lists";
  static apiroleupdatestatus = "Role/UpdateStatus";
  static apirolepagewise = "Role/GetRolePageWise";
  //#endregion

  static apipermissionpagewiserolesave = "Permission";

  //#region :: Permission Record ::
  static apirecordpermissionpagesList = "Permission/GetRecordPermissions";
  static apirecordpermissionpagesSave = "Permission/SetRecordPermissions";
  //#endregion

  //#region :: Dashboard
  static apiDashboard = "Dashboard/";
  static apiGameGetLiveData = "Dashboard/get-live-data"
   static apiGameGetAutoResultData = "Dashboard/get-auto-result"
  //#endregion

  //#region :: Jackpot Master ::
  static apiJackpotMasters = "JackpotMasters/";
  static apiJackpotMastersList = "JackpotMasters/List";
  static apiJackpotMastersUpdateStatus = "JackpotMasters/update-status"; 

 
 
  //#endregion
  //#region :: Yantra Master ::
  static apiYantraMaster = "YantraMasters/";
  static apiYantraMasterList = "YantraMasters/List";
  static apiYantraMasterUpdateStatus = "YantraMasters/update-status"; 
  static apiGameYantraList = "YantraMasters/get-yantra";  
  
  //#endregion
  //#region :: Coin Plan Master ::
    static apicoinPlanMaster = "CoinPlanMasters/";
    static apicoinPlanMasterList = "CoinPlanMasters/List";
    static apicoinPlanMasterUpdateStatus = "CoinPlanMasters/update-status"; 
  //#endregion

   //#region :: Branch Master ::
   static apiBranchMaster = "BranchMasters/";
   static apiBranchMasterList = "BranchMasters/List";
   static apiBranchMasterUpdateStatus = "BranchMasters/update-status"; 
 //#endregion
  
  //#region :: Coin Recharge ::
  static apiCoinRechargeMaster = "CoinRecharge/";
  static apiCoinRechargeMasterList = "CoinRecharge/List";
  static apiCoinRechargeMasterUpdateStatus = "CoinRecharge/update-status";
  static apiGetBranchCurrentBalance = "BranchBalanceSheet/get-currrent-balance/"; 
  static apiGetBranchwiseBalance = "BranchBalanceSheet/get-allbranch-currrent-balance"
  static apiGameYantraResultTist = "Dashboard/get-all-result";
  //#endregion

  //#region :: Transpoter Master ::
  static apiTransporter = "Transporter/";
  static apiTransporterList = "Transporter/List";
  static apiTransporterUpdateStatus = "Transporter/update-status"; 
  //#endregion

  //#region :: Group Master ::
    static apiGroup = "GroupMaster/";
    static apiGroupList = "GroupMaster/List";
    static apiGroupUpdateStatus = "GroupMaster/update-status"; 
  //#endregion

  //#region :: Sub Group Master ::
    static apiSubGroup = "SubGroup/";
    static apiSubGroupList = "SubGroup/List";
    static apiSubGroupUpdateStatus = "SubGroup/update-status"; 
  //#endregion

  //#region :: Sub Group Master ::
    static apiQuality = "QualityMaster/";
    static apiQualityList = "QualityMaster/List";
    static apiQualityUpdateStatus = "QualityMaster/update-status"; 
  //#endregion

  //#region :: CompanyField Master ::
     static apiCompanyFiled = "CompanyFieldMaster/";
     static apiCompanyFiledList = "CompanyFieldMaster/List";
     static apiCompanyFiledUpdateStatus = "CompanyFieldMaster/update-status"; 
  //#endregion

  //#region :: CompanyField Master ::
          static apiDbFieldMaster = "DbFieldMaster/";
          static apiDbFieldMasterList = "DbFieldMaster/List";
          static apiDbFieldMasterUpdateStatus = "DbFieldMaster/update-status"; 
  //#endregion

  //#region :: Broker Master ::
    static apiBrokerMaster = "BrokerMaster/";
    static apiBrokerMasterList = "BrokerMaster/List";
    static apiBrokerMasterUpdateStatus = "BrokerMaster/update-status"; 
    static apiBrokerMasterGetByCode = "BrokerMaster/get-by-code?id=";
  //#endregion

  //#region :: FieldMapping ::
  static apiFieldMapping = "FieldMapping/";
  static apiFieldMappingList = "FieldMapping/List";
  static apiFieldMappingGetByCompanyId = "FieldMapping/get-field-by-company";
  static apiFieldMappingUpdateStatus = "FieldMapping/update-status"; 
//#endregion

  //#region :: Transpoter Master ::
  static apicustomer = "CustomerMaster/";
  static apicustomerList = "CustomerMaster/List";
  static apicustomerUpdateStatus = "CustomerMaster/update-status"; 
  //#endregion

  //#region :: Importer Mater
  static apiImporterList = "ImportDetail/List";
  static apiImporterDetail = "ImportDetail/Post";
  static apiImporterDetailGetById = "ImportDetail/";
  static apiImporterUpload = "ImportDetail/ImportFile";
   //#endregion
   //#region :: Dispatch
   static apiDispatchDetail = "DispatchDetail/";
   static apiDispatchDetailPost = "DispatchDetail/Post";
   static apiDispatchDetailBulkPost = "DispatchDetail/BulkPost";
   static apiDispatchDetailList = "DispatchDetail/List";
   static apiDispatchDetailViewList = "DispatchDetail/get-dispatch-detail";
   static apiDispatchCustomerList = "DispatchDetail/get-customer-filter";
   //#end region
   
   //#region :: Wellknown Master ::
  static apiWellknownMail = "Report/List";
  //#endregion

}

export default UrlConstants;
