
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { defineStore } from "pinia";
// import { AuthService } from '@/composables/api/authService'
// import { useToastStore } from "@/store/useToastStore";
import { useAuthDataStore } from "@/store/useAuthDataStore";
import  { AuthService } from "@/composables/api/authService";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/notfound", name: "error404", component: () => import("@/views/errors/404.vue"),
        meta: { name: "Page not found", requiresAuth: false },
    },
    {
        path: "/:pathMatch(.*)*", name: "NotFound", component: () => import("@/views/errors/404.vue"),
        redirect: { name: "error404" },
        children:[]
    },
    {
        path: "/unauthorized", name: "unauthorized", component: () => import("@/views/errors/401.vue"),
        meta: { name: "unauthorized", requiresAuth: false },
    },
    {
        path: "/forbidden", name: "forbidden", component: () => import("@/views/errors/403.vue"),
        meta: { name: "forbidden", requiresAuth: false },
    },
    {
        path: "/", name: "Login", component: () => import("@/login.vue"),
        meta: { title: "", name: "Login", requiresAuth: false, },
    },
    {
        path: "/Login/ResetPassword", name: "Reset Password", component: () => import("@/resetPassword.vue"),
        meta: { title: "", name: "Reset Password", requiresAuth: false, },
        props: (route) => ({ Id: route.query.Id }),
    },
    {
        path: "/home", name: "Home", components: {
            default: () => import("@/home.vue"),
            layout: () => import("@/components/shared/layout/defaultLayout.vue"),
        },
        meta: { title: "", name: "Home", requiresAuth: true,MainPage:'Home'  },
        redirect: { name: "Dashboard" },
        children: [
            {
                path: "/dashboard", name: "Dashboard", component: () => import("@/views/frontEnd/dashboard.vue"),
                meta: { title: "", name: "Dashboard", requiresAuth: true,MainPage:'Dashboard'  },
            },
            {
                path: "/master", name: "Master", component: () => import("@/views/frontEnd/master.vue"),
                meta: { title: "Yantra App | Master", name: "Master", 
                requiresAuth: true,
                TabName:'master',
                MainPage:'Master'  },
                redirect: { name: "Yantra" },
                children: [
                    {
                        path: "/master/yantra", name: "Yantra", component: () => import("@/views/frontEnd/master/yantra/yantra-list.vue"),
                        meta: { title: "Yantra App | Master | Yantra", name: "Yantra", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/coinPlan", name: "Coin Plan", component: () => import("@/views/frontEnd/master/coinPlan/coinPlan-list.vue"),
                        meta: { title: "Yantra App | Master | Coin Plan", name: "Coin Plan", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/coin-recharge", name: "Coin Recharge", component: () => import("@/views/frontEnd/master/coinRecharge/coinRecharge-list.vue"),
                        meta: { title: "Yantra App | Master | Coin Recharge", name: "Coin Recharge", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/branch", name: "Branch", component: () => import("@/views/frontEnd/master/branch/branch-list.vue"),
                        meta: { title: "Yantra App | Master | Branch", name: "Branch", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/jackpot", name: "Jackpot", component: () => import("@/views/frontEnd/master/jackpot/jackpotList.vue"),
                        meta: { title: "Yantra App | Master | Jackpot", name: "Jackpot", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/transpoter", name: "Transpoter", component: () => import("@/views/frontEnd/master/transpoter/transpoter-list.vue"),
                        meta: { title: "Yantra App | Master | Transpoter", name: "Transpoter", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/group", name: "Group", component: () => import("@/views/frontEnd/master/group/group-list.vue"),
                        meta: { title: "Yantra App | Master | Group", name: "Group", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/subGroup", name: "Sub Group", component: () => import("@/views/frontEnd/master/subGroup/subGroup-list.vue"),
                        meta: { title: "Yantra App | Master | Sub Group", name: "Sub Group", requiresAuth: true, children: [],
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/quality", name: "Quality", component: () => import("@/views/frontEnd/master/quality/quality-list.vue"),
                        meta: { title: "Yantra App | Master | Quality", name: "Quality", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/comapny-filed", name: "Company Filed", component: () => import("@/views/frontEnd/master/companyFiled/companyFiled-list.vue"),
                        meta: { title: "Yantra App | Master | Company Filed", name: "Company Filed", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/db-filed", name: "DB Filed", component: () => import("@/views/frontEnd/master/dbFiled/dbFiled-list.vue"),
                        meta: { title: "Yantra App | Master | DB Filed", name: "DB Filed", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/field-mapping", name: "Field Mapping", component: () => import("@/views/frontEnd/master/fieldMapping/fieldMapping-list.vue"),
                        meta: { title: "Yantra App | Master | Field Mapping", name: "Field Mapping", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/broker", name: "Broker", component: () => import("@/views/frontEnd/master/broker/broker-list.vue"),
                        meta: { title: "Yantra App | Master | Broker", name: "Broker", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                    {
                        path: "/master/customer", name: "Customer", component: () => import("@/views/frontEnd/master/customer/customer-list.vue"),
                        meta: { title: "Yantra App | Master | Customer", name: "Customer", requiresAuth: true, children: [], 
                        TabName:'master',
                        MainPage:'Master' 
                        },
                    },
                ]
            },
            {
                path: "/import", name: "Import", component: () => import("@/views/frontEnd/import.vue"),
                meta: { title: "Yantra App | Import", name: "Import", 
                requiresAuth: true,
                TabName:'import',
                MainPage:'Import'  },
                redirect: { name: "Upload Excel" },
                children: [
                    {
                        path: "/import/upload-excel", name: "Upload Excel", component: () => import("@/views/frontEnd/import/uploadExcel/uploadExcel.vue"),
                        meta: { title: "Yantra App | Import | Upload Excel", name: "Upload Excel",
                        requiresAuth: true,  
                        TabName:'import',
                        MainPage:'Import' 
                        },
                    }
                ]
            },
            {
                path: "/dispatch", name: "Dispatch", component: () => import("@/views/frontEnd/dispatch.vue"),
                meta: { title: "Yantra App | Dispatch", name: "Dispatch", 
                requiresAuth: true,
                TabName:'dispatch',
                MainPage:'Dispatch'  },
                redirect: { name: "Dispatch" },
                children: [
                    {
                        path: "/dispatch", name: "Dispatch", component: () => import("@/views/frontEnd/dispatch/dispatch-list.vue"),
                        meta: { title: "Yantra App | Dispatch", name: "Dispatch",
                        requiresAuth: true,  
                        TabName:'dispatch',
                        MainPage:'Dispatch' 
                        },
                    }
                ]
            },
            {
                path: "/report", name: "Report", component: () => import("@/views/frontEnd/report.vue"),
                meta: { title: "Yantra App | Report", name: "Report", 
                requiresAuth: true,
                TabName:'report',
                MainPage:'Report'  },
                redirect: { name: "Balance" },
                children: [
                    {
                        path: "/report/branch-balance", name: "Balance", component: () => import("@/views/frontEnd/report/branchBalCheck/branchBalReport.vue"),
                        meta: { title: "Yantra App | Report | Balance", name: "Balance",children: [],
                        requiresAuth: true,  
                        TabName:'report',
                        MainPage:'Report' 
                        },
                    },
                    {
                        path: "/report/challan-register", name: "Challan Register", component: () => import("@/views/frontEnd/report/importReport/importReport.vue"),
                        meta: { title: "Yantra App | Report | Challan Register", name: "Challan Register",children: [],
                        requiresAuth: true,  
                        TabName:'report',
                        MainPage:'Report' 
                        },
                    },
                    {
                        path: "/report/wellknown-mail", name: "Wellknown Mail", component: () => import("@/views/frontEnd/report/wellknownMail/wellknownMail.vue"),
                        meta: { title: "Yantra App | Report | Wellknown Mail", name: "Wellknown Mail",children: [],
                        requiresAuth: true,  
                        TabName:'report',
                        MainPage:'Report' 
                        },
                    }
                ]
            },
            {
                path: "/admin", name: "Admin", component: () => import("@/views/frontEnd/admin.vue"),
                meta: { title: "Yantra App | Admin", name: "Admin", 
                requiresAuth: true,
                TabName:'admin',
                MainPage:'Admin'  },
                redirect: { name: "Users" },
                children: [
                    {
                        path: "/admin/users", name: "Users", component: () => import("@/views/frontEnd/admin/userRolesPermission/users/usersList.vue"),
                        meta: { title: "Yantra App | Admin | User", name: "User", requiresAuth: true, children: [], 
                        TabName:'admin',
                        MainPage:'Admin' 
                        },
                    }
                ]
            }
        ],
    },      

];
const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const authLogin = useAuthDataStore().getAuth as any;    
    if (to.meta.requiresAuth) {
        let prm = true
        if((to?.meta?.MainPage ?? '') == 'Dashboard' && (authLogin?.Token ?? "").length > 0)
        {
            prm=true
        }
        return (authLogin?.Token ?? "").length == 0 ? next({ name: "Login", query: { redirect: to.fullPath, } }) : next();
    }
    else return next();
    

})
export default router;
